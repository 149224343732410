import axios from "axios";
import { serverUrl } from "../common/contants";
import { auth } from "../../configurations/firebaseConfig";
import { getToken, getUid } from "./AuthManager";
import getApiInstance from "../common/api";
import OnevocaServerError from "../common/OnevocaServerError";
import { updateProfile } from "firebase/auth";

export async function fetchTemporaryName(): Promise<string> {
  try {
    const response = await axios.get(
      `${serverUrl}/user_information/make_temporary_name.php`
    );
    if (response.data.error !== undefined && response.data.error !== null) {
      return Promise.reject(new Error(response.data.error));
    } else {
      return response.data.temporary;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUser(): Promise<void> {
  try {
    const token = await getToken();
    const response = await axios.get(`${serverUrl}/user/updateAccount.php`, {
      params: {
        uname: auth.currentUser?.displayName,
        uemail: auth.currentUser?.providerData[0].email,
        os: "web",
        app_version: process.env.REACT_APP_VERSION,
        device: navigator.userAgent,
        platform: auth.currentUser?.providerData[0].providerId,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        system_language: navigator.language,
        new_v: "1",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.error !== undefined && response.data.error !== null) {
      return Promise.reject(new Error(response.data.error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getSlots(): Promise<string> {
  try {
    const uid = await getUid();
    const response = await getApiInstance(undefined).get(
      "/user_information/get_slot.php",
      {
        params: {
          uid: uid,
          data: "slot",
        },
      }
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    }

    const slot = response.data.slot;
    if (slot !== undefined && typeof slot === "string") {
      return slot;
    } else {
      return Promise.reject(new Error("?"));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateProfileImage(image: string): Promise<void> {
  const response = await fetch(image);
  const blob = await response.blob();
  const newFileName = `${auth.currentUser?.providerData[0].uid}`;
  const jpgFile = new File([blob], newFileName, { type: "image/jpeg" });
  const formData = new FormData();
  formData.append("image", jpgFile);

  try {
    const uid = await getUid();
    const response = await getApiInstance(undefined).post(
      `/profile/update_profile_image.php?uid=${uid}`,
      formData
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteProfileImage(): Promise<void> {
  try {
    const uid = await getUid();
    const parameters = {
      uid: uid,
    };
    const response = await getApiInstance(undefined).post(
      "/profile/delete_profile_image.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUsername(username: string): Promise<void> {
  return updateProfile(auth.currentUser!, {
    displayName: username,
  });
}

export async function deleteUser(): Promise<void> {
  try {
    const token = await getToken();
    const response = await getApiInstance(token).get("/user/deleteUser.php");
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
